@font-face {
  font-family: 'Basier Circle';
  src: url('../../Assets/Fonts/BasierCircle/BasierCircle-Bold.ttf');
  src: local('Basier Circle Bold'), local('BasierCircle-Bold'),
    url('../../Assets/Fonts/BasierCircle/BasierCircle-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../../Assets/Fonts/BasierCircle/BasierCircle-SemiBold.ttf');
  src: local('Basier Circle SemiBold'), local('BasierCircle-SemiBold'),
    url('../../Assets/Fonts/BasierCircle/BasierCircle-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../../Assets/Fonts/BasierCircle/BasierCircle-Medium.ttf');
  src: local('Basier Circle Medium'), local('BasierCircle-Medium'),
    url('../../Assets/Fonts/BasierCircle/BasierCircle-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../../Assets/Fonts/BasierCircle/BasierCircle-Regular.ttf');
  src: local('Basier Circle Regular'), local('BasierCircle-Regular'),
    url('../../Assets/Fonts/BasierCircle/BasierCircle-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

div {
  scroll-behavior: smooth;
}

html,
body,
#scrollContainer {
  overscroll-behavior: none;
}

.blink-btn {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}