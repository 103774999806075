/* Created By ND */
body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing : antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #E5E5E5 !important;
}

.font-link {
  font-family: 'Montserrat', cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-size-36 {
  font-size: 2.25rem !important;
}

.p-8 {
  padding: 0.5rem !important;
}

/* for number textfield remove up down arrow */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin            : 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

*::-webkit-scrollbar {
  width : 0.275rem;
  height: 0.375rem;
}

.reputationPreview::-webkit-scrollbar {
  width : 0 !important;
}

*::-webkit-scrollbar-track {
  border-radius: 0.625rem;
  background   : rgba(0, 0, 0, 0.06);
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  background   : rgba(0, 0, 0, 0.16);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.36);
}

*::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .5);
}

.MuiListItem-root .MuiListItemButton-root .MuiTouchRipple-root {
  overflow      : unset !important;
  pointer-events: none !important;
  position      : unset !important;
  z-index       : unset !important;
  top           : 0;
  right         : 0;
  bottom        : 0;
  left          : 0;
  border-radius : unset !important;
}

/* for helper text */
.MuiFormHelperText-root {
  font-family: 'Montserrat' !important
}

@tailwind base;
@tailwind components;
@tailwind utilities;